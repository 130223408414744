import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { CodeUncontrolled, Snippet } from '../../CodeSample';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <CodeUncontrolled {...props}>{props.children}</CodeUncontrolled>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Snippet language="html-tags" mdxType="Snippet">
      <pre {...{
        "className": "quiet-light vscode-highlight",
        "data-language": "html"
      }}><code parentName="pre" {...{
          "className": "vscode-highlight-code"
        }}><span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk20"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`mt-subscribe`}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk11 mtki"
            }}>{`planId`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`="`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`3470cde5-eb14-4e15-bd8d-a8360e1d9f4a`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{` />`}</span></span></code></pre>
    </Snippet>
    <Snippet language="html-attributes" mdxType="Snippet">
      <pre {...{
        "className": "quiet-light vscode-highlight",
        "data-language": "html"
      }}><code parentName="pre" {...{
          "className": "vscode-highlight-code"
        }}><span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk20"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`form`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk20"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk11 mtki"
            }}>{`data-mt-action-form`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`="`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`subscribe`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk20"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk11 mtki"
            }}>{`data-mt-plan-id`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`="`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`3470cde5-eb14-4e15-bd8d-a8360e1d9f4a`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk20"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`input`}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk11 mtki"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`="`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`text`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk11 mtki"
            }}>{`data-mt-action-form-field`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`="`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{` />`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`input`}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk11 mtki"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`="`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`text`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk11 mtki"
            }}>{`data-mt-action-form-field`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`="`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`coupon`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{` />`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`div`}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk11 mtki"
            }}>{`data-mt-action-form-field`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`="`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`creditCard`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{`></`}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`div`}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`input`}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk11 mtki"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`="`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`submit`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk11 mtki"
            }}>{`value`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`="`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`Subscribe`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{` />`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk20"
            }}>{`</`}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`form`}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{`>`}</span></span></code></pre>
    </Snippet>
    <Snippet language="js" mdxType="Snippet">
      <pre {...{
        "className": "quiet-light vscode-highlight",
        "data-language": "javascript"
      }}><code parentName="pre" {...{
          "className": "vscode-highlight-code"
        }}><span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk10"
            }}>{`await`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Midtype`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`actions`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk9 mtkb"
            }}>{`subscribe`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`{`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  planId`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`3470cde5-eb14-4e15-bd8d-a8360e1d9f4a`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  name`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`John Smith`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  coupon`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`FREE_FOREVER`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk6"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`// Card token generated by Stripe.js`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  stripeToken`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`tok_1EBSGVAJkPH6BlKfAD5nURdn`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk6"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}></span></span></code></pre>
    </Snippet>
    <Snippet language="react" mdxType="Snippet">
      <pre {...{
        "className": "quiet-light vscode-highlight",
        "data-language": "jsx"
      }}><code parentName="pre" {...{
          "className": "vscode-highlight-code"
        }}><span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk10"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`React`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`useState`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`from`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`react`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`';`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk10"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`gql`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`from`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`graphql-tag`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`';`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk10"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`useMutation`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`from`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`@apollo/react-hooks`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`';`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}></span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk7"
            }}>{`const`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`CREATE_SUBSCRIPTION`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9 mtkb"
            }}>{`gql`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`\``}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk4"
            }}>{`  mutation CreateSubscription(`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk4"
            }}>{`    $planId: UUID!`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk4"
            }}>{`    $token: String!`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk4"
            }}>{`    $coupon: String`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk4"
            }}>{`  ) {`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk4"
            }}>{`    createMStripeSubscription(`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk4"
            }}>{`      input: { paymentSourcePid: $token, planId: $planId, couponPid: $coupon }`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk4"
            }}>{`    ) {`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk4"
            }}>{`      mStripeSubscription {`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk4"
            }}>{`        subscriber {`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk4"
            }}>{`          id`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk4"
            }}>{`          subscription {`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk4"
            }}>{`            id`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk4"
            }}>{`            active`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk4"
            }}>{`          }`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk4"
            }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk4"
            }}>{`      }`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk4"
            }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk4"
            }}>{`  }`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk6"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}></span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk7"
            }}>{`const`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`STRIPE_TOKEN`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`xxxxx`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`;`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`// Credit card token generated using the Stripe.js Elements library.`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk7"
            }}>{`const`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`PLAN_ID`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`3470cde5-eb14-4e15-bd8d-a8360e1d9f4a`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}></span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk7"
            }}>{`const`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9 mtkb"
            }}>{`SubscribeBox`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`()`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`=>`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`{`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`const`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`[`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`coupon`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`setCoupon`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`]`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9 mtkb"
            }}>{`useState`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`''`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`const`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`[`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`createSubscription`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`]`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9 mtkb"
            }}>{`useMutation`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`CREATE_SUBSCRIPTION`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`{`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    variables`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`{`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`      token`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`STRIPE_TOKEN`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`      planId`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`PLAN_ID`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`coupon`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`return`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` (`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`form`}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk19"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`input`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk20"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk11 mtki"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`text`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk20"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk11 mtki"
            }}>{`value`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`={`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`coupone`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk20"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk11 mtki"
            }}>{`onChange`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`={`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`e`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`=>`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9 mtkb"
            }}>{`setCoupon`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`e`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`target`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`value`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk20"
            }}>{`      />`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk19"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`div`}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk11 mtki"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`stripeCreditCard`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{` />`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk19"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`input`}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk11 mtki"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`submit`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk11 mtki"
            }}>{`value`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`Subscribe`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk11 mtki"
            }}>{`onClick`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`={`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`createSubscription`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{` />`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk19"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{`</`}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`form`}</span><span parentName="span" {...{
              "className": "mtk20"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  )`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk6"
            }}>{`};`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}></span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk10"
            }}>{`export`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`default`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`SubscribeBox`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`;`}</span></span></code></pre>
    </Snippet>


    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`:root {
  --vscode-highlight-padding-v: 1rem;
  --vscode-highlight-padding-h: 1.5rem;
  --vscode-highlight-padding-top: var(--vscode-highlight-padding-v);
  --vscode-highlight-padding-right: var(--vscode-highlight-padding-h);
  --vscode-highlight-padding-bottom: var(--vscode-highlight-padding-v);
  --vscode-highlight-padding-left: var(--vscode-highlight-padding-h);
  --vscode-highlight-border-radius: 8px;

  --vscode-highlight-line-highlighted-background-color: transparent;
  --vscode-highlight-line-highlighted-border-width: 4px;
  --vscode-highlight-line-highlighted-border-color: transparent;
}

.vscode-highlight {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: 1rem;
  padding-top: var(--vscode-highlight-padding-top);
  padding-bottom: 1rem;
  padding-bottom: var(--vscode-highlight-padding-bottom);
  border-radius: 8px;
  border-radius: var(--vscode-highlight-border-radius);
  font-feature-settings: normal;
}

.vscode-highlight-code {
  display: inline-block;
  min-width: 100%;
}

.vscode-highlight-line {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  padding-left: 1.5rem;
  padding-left: var(--vscode-highlight-padding-left);
  padding-right: 1.5rem;
  padding-right: var(--vscode-highlight-padding-right);
}

.vscode-highlight-line-highlighted {
  background-color: var(--vscode-highlight-line-highlighted-background-color);
  box-shadow: inset var(--vscode-highlight-line-highlighted-border-width) 0 0 0 var(--vscode-highlight-line-highlighted-border-color);
}
.quiet-light {
background-color: #F5F5F5;
}

.quiet-light .mtk1 { color: #333333; }
.quiet-light .mtk2 { color: #F5F5F5; }
.quiet-light .mtk3 { color: #AAAAAA; }
.quiet-light .mtk4 { color: #448C27; }
.quiet-light .mtk5 { color: #9C5D27; }
.quiet-light .mtk6 { color: #777777; }
.quiet-light .mtk7 { color: #7A3E9D; }
.quiet-light .mtk8 { color: #660000; }
.quiet-light .mtk9 { color: #AA3731; }
.quiet-light .mtk10 { color: #4B69C6; }
.quiet-light .mtk11 { color: #8190A0; }
.quiet-light .mtk12 { color: #96000014; }
.quiet-light .mtk13 { color: #000000; }
.quiet-light .mtk14 { color: #FFFFDD; }
.quiet-light .mtk15 { color: #FFDDDD; }
.quiet-light .mtk16 { color: #DDFFDD; }
.quiet-light .mtk17 { color: #434343; }
.quiet-light .mtk18 { color: #DDDDFF; }
.quiet-light .mtk19 { color: #333333FF; }
.quiet-light .mtk20 { color: #91B3E0; }
.quiet-light .mtk21 { color: #EAEBE6; }
.quiet-light .mtki { font-style: italic; }
.quiet-light .mtkb { font-weight: bold; }
.quiet-light .mtku { text-decoration: underline; text-underline-position: under; }`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      