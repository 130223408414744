import React from 'react';
import styled from 'styled-components';
import { colors } from 'midtype-backend';

import Arrow from '../icons/Arrow';

interface IPromoProps {
  text: string;
}

const Styled = styled.div`
  display: flex;
  width: fit-content;
  margin: auto;
  margin-bottom: 2rem;
  border-radius: 1rem;
  font-size: 0.9rem;
  padding: 0.4rem 1rem;
  background: ${colors.PURPLE_LIGHT(0.1)};
  font-weight: 600;
  color: ${colors.PURPLE_LIGHT()};
  align-items: center;
  &:before {
    content: 'NEW';
    color: ${colors.WHITE()};
    margin: -0.3rem 0.75rem -0.3rem -0.8rem;
    padding: 0.3rem 0.6rem;
    border-radius: 1rem;
    background: ${colors.PURPLE_LIGHT()};
    font-size: 0.6rem;
    display: block;
    letter-spacing: 1px;
  }

  svg {
    fill: ${colors.PURPLE_LIGHT()};
    margin-left: 0.5rem;
    transition: 250ms transform;
  }
  &:hover svg {
    transform: translateX(3px);
  }
`;

const Promo: React.FC<IPromoProps> = props => {
  return (
    <Styled className="promo">
      <div className="promo__title">{props.text}</div>
      <Arrow />
    </Styled>
  );
};

export default Promo;
