import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { colors } from 'midtype-backend';

import Logo from './Logo';

const Styled = styled.footer`
  width: 100vw;
  padding: 0 30px;
  margin-bottom: 30px;
  .footer__links {
    max-width: 1280px;
    margin: auto;
    padding: 30px 50px 0 50px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    border-top: 1px solid ${colors.GRAY_2()};
  }
  .footer__links__col {
    flex-flow: column;
    align-items: flex-start;
  }
  .footer__links__link {
    text-decoration: none;
    margin: 10px 0;
    opacity: 0.8;
    transition: 250ms all;
  }
  .footer__links__link:hover {
    opacity: 1;
  }
  @media only screen and (max-width: 768px) {
    .footer__links {
      padding: 0px;
      padding-top: 30px;
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 40px 1fr;
    }
    .footer__links__logo {
      text-align: center;
      grid-column: 1 / span 3;
    }
  }
`;

interface IFooterLinkProps {
  text: string;
  link: string;
}

const FooterLink: React.FC<IFooterLinkProps> = props => (
  <Link to={props.link} className="footer__links__link">
    <h5 className="text-gray">{props.text}</h5>
  </Link>
);

const Footer: React.FC = () => (
  <Styled>
    <div className="footer__links">
      <div className="footer__links__logo">
        <Logo width={110} />
      </div>
      <div className="footer__links__col flex">
        <FooterLink link="/about" text="About" />
      </div>
      <div className="footer__links__col flex">
        <FooterLink
          link="/blog/firebase-alternative/"
          text="Compare to Firebase"
        />
        <FooterLink link="/blog" text="Blog" />
      </div>
      <div className="footer__links__col flex">
        <FooterLink link="/privacy-policy" text="Privacy Policy" />
        <FooterLink link="/terms" text="Terms of Service" />
      </div>
    </div>
  </Styled>
);

export default Footer;
