import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { colors, analytics } from 'midtype-backend';

import Installation from './code/Installation';
import Signup from './code/Signup';
import Login from './code/Login';
import Vote from './code/Vote';
import CreateRecord from './code/CreateRecord';
import Subscribe from './code/Subscribe';

import IconCode from '../../icons/Code';
import IconDatabase from '../../icons/Database';
import ShieldCheck from '../../icons/ShieldCheck';
import IconRocket from '../../icons/Rocket';
import IconThumbsUp from '../../icons/ThumbsUp';
import IconMoney from '../../icons/MoneyCheckAlt';

import Image from '../Image';

const Styled = styled.div`
  width: 100%;
  padding: 3rem 0;
  padding-bottom: 5rem;
  display: flex;
  flex-flow: column;

  .container {
    width: 100%;
    max-width: 60rem;
    margin: auto;
  }

  .integrations {
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 2rem;
  }
  .integrations__logos {
    display: flex;
    flex-flow: row wrap;
    margin-left: 1rem;
  }
  .integration {
    margin: 1rem 1.5rem;
    height: 2rem;
    text-align: center;
    filter: grayscale(100%);
    opacity: 0.3;
    transition: 250ms all;
    cursor: pointer;
  }
  .integration__logo {
    overflow: hidden;
    width: 2rem;
    height: 100%;
    margin: auto;
    margin-bottom: 0.5rem;
  }
  .integration--Webflow {
    .integration__logo {
      width: 3.5rem;
    }
  }
  .integration:hover,
  .integration--active {
    opacity: 1;
    filter: none;
  }
  .integration__logo .gatsby-image-wrapper {
    height: 100%;
  }
  .integration label {
    font-size: 0.7rem;
  }
  .demo {
    display: grid;
    grid-template-columns: 40rem auto;
    grid-gap: 2rem;
    margin-bottom: 3rem;
  }
  .demo__code .code-sample {
    border-radius: 5px;
    overflow: hidden;
    padding: 0;
    border-radius: 5px;
    box-shadow: 0 10px 60px ${colors.BLACK(0.15)};
  }
  .demo__code .code-container {
    position: relative;
    max-height: 350px;
    overflow-y: auto;
    background: #f5f5f5;
    z-index: 1;
  }
  .demo__code--open .code-container {
    max-height: none;
  }
  .demo__code .code-sample .header {
    background: ${colors.WHITE()};
  }
  .demo__code__show-more {
    background: ${colors.PURPLE_LIGHT(0.05)};
    padding: 1rem;
    border-radius: 0 0 5px 5px;
    border-top: 1px solid ${colors.GRAY_2()};
    visibility: hidden;
    transition: 500ms all;
    transform: translateY(-20px);
    opacity: 0;
    position: relative;
    z-index: 0;
    max-width: 40rem;
    margin: auto;
  }
  .demo__code__show-more button {
    background: none;
    font-weight: 600;
  }
  .demo__code__show-more--open {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
  .demo__options {
    position: relative;
  }
  .demo__options__highlight {
    position: absolute;
    width: 100%;
    height: 2.5rem;
    background: ${colors.PURPLE_LIGHT(0.1)};
    border-left: 3px solid ${colors.PURPLE_LIGHT()};
    border-radius: 3px;
    top: 0;
    transition: 250ms all;
  }
  .demo__options__option {
    position: relative;
    z-index: 1;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    height: 2.5rem;
    padding-left: 1rem;
    opacity: 0.5;
    color: ${colors.GRAY_3()};
    transition: 250ms all;
  }
  .demo__options__option svg {
    color: ${colors.GRAY_3()};
    margin-right: 0.5rem;
  }
  .demo__options__option:hover {
    opacity: 1;
  }
  .demo__options__option--active {
    opacity: 1;
    color: ${colors.PURPLE_LIGHT()};
  }
  .demo__options__option--active svg {
    fill: ${colors.PURPLE_LIGHT()};
  }
  @media screen and (max-width: 800px) {
    padding-top: 0;
    .demo {
      grid-template-columns: 1fr;
      grid-gap: 1rem;
      padding: 0 2rem;
    }
    .demo__code__show-more {
      display: none;
    }
    .demo .code-container {
      max-width: calc(100vw - 4rem);
      overflow-x: auto;
    }
    .demo__options {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
      grid-row-start: 1;
      grid-row-end: 1;
      margin-bottom: 2rem;
    }
    .demo__options__option svg {
      min-width: 1.5rem;
      height: 1.5rem;
      margin-right: 1rem;
    }
    .demo__options__highlight {
      display: none;
    }
    .integrations__logos {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 2rem;
    }
  }
`;

const Integration: React.FC<any> = props => {
  return (
    <div
      className={`integration integration--${props.title} integration--${
        props.active ? 'active' : 'inactive'
      }`}
      onClick={props.onClick}
    >
      <div className="integration__logo">
        <Image
          src={props.logo || `logos/${props.title}.png`}
          style={{ objectFit: 'contain' }}
        />
      </div>
      <label>{props.title}</label>
    </div>
  );
};

const INTEGRATION_MAPPING: { [key: string]: string[] } = {
  'html-tags': ['Carrd', 'Hugo', 'Wordpress'],
  'html-attributes': ['Webflow', 'Carrd'],
  js: ['VueJS', 'Gatsby'],
  react: ['React']
};

const INTEGRATIONS: { [key: string]: string } = {
  Webflow: 'html-attributes',
  Wordpress: 'html-tags',
  Carrd: 'html-attributes',
  Hugo: 'html-tags',
  Gatsby: 'js',
  VueJS: 'js',
  React: 'react'
};

interface IDemo {
  fc: React.FC<any>;
  label: string;
  description: string;
  icon?: JSX.Element;
}

const DEMOS: { [key: string]: IDemo } = {
  installation: {
    fc: Installation,
    label: 'Install',
    description: '',
    icon: <IconCode />
  },
  signup: {
    fc: Signup,
    label: 'Signup User',
    description: '',
    icon: <IconRocket />
  },
  login: {
    fc: Login,
    label: 'Login User',
    description: '',
    icon: <ShieldCheck />
  },
  subscribe: {
    fc: Subscribe,
    label: 'Subscription Payment',
    description: '',
    icon: <IconMoney />
  },
  vote: {
    fc: Vote,
    label: 'Upvote Item',
    description: '',
    icon: <IconThumbsUp />
  },
  createRecord: {
    fc: CreateRecord,
    label: 'Add New Record',
    description: '',
    icon: <IconDatabase />
  }
};

const DEFAULT_LANGUAGE = 'html-tags';
const DEFAULT_DEMO = 'installation';

const SectionInstallation: React.FC = () => {
  const [lang, setLang] = useState(DEFAULT_LANGUAGE);
  const [active, setActive] = useState(DEFAULT_DEMO);
  const [heightOpen, setHeightOpen] = useState(false);
  const [height, setHeight] = useState(0);

  const codeEl = useRef(null);

  useEffect(() => {
    if (!codeEl || !codeEl.current) {
      return;
    }
    const snippet = codeEl.current.querySelector(`.snippet--open`);
    if (!snippet) {
      return;
    }
    const h = snippet.offsetHeight;
    setHeight(Math.max(h - 350, 0));
  }, [lang, codeEl, active]);

  const integrations = INTEGRATION_MAPPING[lang];
  const Demo = DEMOS[active].fc;

  return (
    <Styled>
      <div className="container">
        <div className="demo">
          <div
            className={`demo__code demo__code--${
              heightOpen ? 'open' : 'closed'
            }`}
            ref={codeEl}
          >
            <Demo
              active={lang}
              setActive={(newLang: string) => {
                analytics.track('Changed Code Snippet Language', {
                  language: newLang
                });
                setLang(newLang);
              }}
            />
            <div
              className={`text-small demo__code__show-more demo__code__show-more--${
                height ? 'open' : 'closed'
              }`}
            >
              <button onClick={() => setHeightOpen(!heightOpen)}>
                Show {heightOpen ? 'Less' : 'More'}
              </button>
            </div>
          </div>
          <div className="demo__options">
            <div
              className="demo__options__highlight"
              style={{
                top: `${Object.keys(DEMOS).indexOf(active) * 2.5}rem`
              }}
            />
            {Object.keys(DEMOS).map(key => (
              <h5
                key={key}
                className={`demo__options__option demo__options__option--${
                  active === key ? 'active' : 'inactive'
                }`}
                onClick={() => {
                  analytics.track('Changed Homepage Code Demo', {
                    demo: DEMOS[key].label
                  });
                  setActive(key);
                }}
              >
                {DEMOS[key].icon} {DEMOS[key].label}
              </h5>
            ))}
          </div>
        </div>
        <div className="integrations">
          <div className="integrations__logos">
            {Object.keys(INTEGRATIONS).map(key => (
              <Integration
                key={key}
                title={key}
                active={integrations.includes(key)}
                onClick={() => {
                  analytics.track('Changed Homepage Code Platform', {
                    platform: key
                  });
                  setLang(INTEGRATIONS[key]);
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default SectionInstallation;
