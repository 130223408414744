import React from 'react';
import styled from 'styled-components';
import { colors, styles } from 'midtype-backend';

const LINE_HEIGHT = 50;
const LINE_WIDTH = 1;
const LINE_MARGIN = 0.75;

const Styled = styled.div`
  position: absolute;
  top: -4rem;
  left: 0;
  display: flex;
  transform: rotate(30deg);
  .line {
    width: ${LINE_WIDTH}rem;
    height: ${LINE_HEIGHT}rem;
    margin-right: ${LINE_MARGIN}rem;
    background-image: linear-gradient(
      -180deg,
      ${colors.PURPLE_DARK()} 0%,
      ${colors.WHITE(0)} 100%
    );
  }
  .line--2 {
    height: ${LINE_HEIGHT * 0.5}rem;
  }
  .line--4 {
    transform: translateY(-20rem);
  }
  @media only screen and (max-width: 1024px) {
    left: -6rem;
  }
  @media only screen and (max-width: 500px) {
    display: none;
  }
`;

const PatternLines: React.FC = () => (
  <Styled>
    <div className="line line--1" />
    <div className="line line--2" />
    <div className="line line--3" />
    <div className="line line--4" />
  </Styled>
);

export default PatternLines;
