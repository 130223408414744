import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import GlobalStyle from './GlobalStyle';
import Header from '../components/HeaderBanner';
import Footer from '../components/Footer';
import PatternLines from '../components/PatternLines';
import Icon from '../images/midtype-logo.png';

interface IQuery {
  site: {
    siteMetadata: ISiteMetadata;
  };
}

interface IProps {
  pageTitle?: string;
  hideLines?: boolean;
  darkNav?: boolean;
  image?: string;
  pageDescription?: string;
}

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        twitterHandle
      }
    }
  }
`;

const Main = styled.main`
  margin-top: 3.75rem;
`;

const Layout: React.FC<IProps> = props => {
  const data: IQuery = useStaticQuery(query);
  const { title, twitterHandle } = data.site.siteMetadata;

  const pageTitle = props.image
    ? props.pageTitle
    : `${title}${props.pageTitle ? ` | ${props.pageTitle}` : ''}`;
  const pageDescription = props.pageDescription;
  const pageImage = props.image;
  const metaValues = [
    {
      property: 'og:type',
      content: 'article'
    },
    {
      name: 'twitter:card',
      content: 'summary'
    },
    {
      name: 'twitter:creator',
      content: twitterHandle
    },
    {
      property: 'og:title',
      content: pageTitle
    },
    {
      name: 'twitter:title',
      content: pageTitle
    }
  ];
  if (pageDescription) {
    metaValues.push(
      {
        name: `description`,
        content: pageDescription
      },
      {
        property: `og:description`,
        content: pageDescription
      },
      {
        name: `twitter:description`,
        content: pageDescription
      }
    );
  }
  if (pageImage) {
    metaValues.push(
      {
        property: `og:image`,
        content: pageImage
      },
      {
        name: 'twitter:image',
        content: pageImage
      }
    );
  }
  return (
    <React.Fragment>
      <Helmet
        link={[
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '16x16',
            href: `${Icon}`
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '32x32',
            href: `${Icon}`
          },
          { rel: 'shortcut icon', type: 'image/png', href: `${Icon}` }
        ]}
        title={pageTitle}
        meta={metaValues}
      />
      <Header dark={props.darkNav} />
      {!props.hideLines && <PatternLines />}
      <Main>{props.children}</Main>
      <Footer />
      <GlobalStyle />
    </React.Fragment>
  );
};

export default Layout;
