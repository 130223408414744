import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { colors, styles } from 'midtype-backend';

import IllustrationUsers from '../../icons/IllustrationUsers';
import LogoGoogle from '../../icons/LogoGoogle';
import LogoGithub from '../../icons/LogoGithub';
import Image from '../Image';

interface IProps {
  className: string;
  style?: CSSProperties;
}

const Styled = styled.div`
  position: relative;
  width: 100%;
  .users-list {
    width: 100%;
    max-height: 24rem;
    overflow-y: hidden;
    position: relative;
  }
  .users-list:after {
    content: '';
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(
      0deg,
      ${colors.GRAY_1(1)} 0%,
      ${colors.GRAY_1(0)} 50%,
      ${colors.GRAY_1(1)} 100%
    );
  }
  .users-list__carousel {
    /* transform: translate3d(0, 0, 0);
    will-change: transform;
    animation-name: carousel;
    animation-duration: 12s;
    animation-iteration-count: infinite;
    animation-timing-function: linear; */
  }
  & > svg {
    position: absolute;
    right: 0;
    bottom: -0.5rem;
  }
  @keyframes carousel {
    0% {
      transform: translateY(-24rem);
    }
    100% {
      transform: translateY(0rem);
    }
  }
  @media screen and (max-width: 768px) {
    .users-list {
      max-height: 18rem;
    }
  }
`;

interface IUserBadgeProps {
  name: string;
  type: string;
  pic: string;
  timeAgo: string;
  subtype?: string;
  providerLogo: React.FC;
}

const StyledUserBadge = styled.div`
  padding: 1rem;
  width: 100%;
  height: 5rem;
  margin-bottom: 1rem;
  background: ${colors.WHITE()};
  box-shadow: ${styles.BOX_SHADOW};
  border-radius: 5px;
  grid-gap: 1rem;
  grid-template-columns: 3rem 5fr 3fr 1fr;
  .avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    overflow: hidden;
  }
  .avatar .gatsby-image-wrapper {
    height: 100%;
  }
  p {
    color: ${colors.BLUE_DARK()};
    margin: 0;
    text-align: left;
    align-self: start;
  }
  @media screen and (max-width: 768px) {
    p {
      align-self: center;
    }
  }
`;

const UserBadge: React.FC<IUserBadgeProps> = props => (
  <StyledUserBadge className="grid">
    <div className="avatar">
      <Image src={props.pic} />
    </div>
    <p className="text-small">
      <b>{props.name}</b>
      <br />
      <span className="text-gray drop-at-med">
        Last logged in {props.timeAgo}.
      </span>
    </p>
    <p className="text-small">
      <b>{props.type}</b>
      {props.subtype && (
        <span className="drop-at-med">
          <br />
          {props.subtype}
        </span>
      )}
    </p>
    <div className="provider">
      <props.providerLogo />
    </div>
  </StyledUserBadge>
);

const UsersBlock: React.FC = () => (
  <>
    <UserBadge
      name="Douglas Cantrell"
      type="Paid User"
      subtype="Gold Tier"
      pic="avatars/avatar-1.jpg"
      timeAgo="34 minutes ago"
      providerLogo={LogoGoogle}
    />
    <UserBadge
      name="Orlando White"
      type="User"
      pic="avatars/avatar-2.jpg"
      timeAgo="4 hours ago"
      providerLogo={LogoGithub}
    />
    <UserBadge
      name="Mary Switzer"
      type="User"
      pic="avatars/avatar-3.jpg"
      timeAgo="2 days ago"
      providerLogo={LogoGoogle}
    />
    <UserBadge
      name="Elizabeth Newhouse"
      type="Paid User"
      subtype="Bronze Tier"
      pic="avatars/avatar-4.jpg"
      timeAgo="last week"
      providerLogo={LogoGithub}
    />
  </>
);

const UsersList: React.FC<IProps> = props => {
  return (
    <Styled className={`flex ${props.className}`} style={props.style}>
      <div className="users-list">
        <div className="users-list__carousel">
          <UsersBlock />
        </div>
      </div>
      <IllustrationUsers width="100px" height="14rem" />
    </Styled>
  );
};

export default UsersList;
