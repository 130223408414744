import * as React from "react";

const SvgServerlessIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 346 288" {...props}>
    <defs>
      <linearGradient
        x1="50%"
        y1="100%"
        x2="50%"
        y2="0%"
        id="serverless-illustration_svg__a"
      >
        <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
        <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
        <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="100%"
        x2="50%"
        y2="0%"
        id="serverless-illustration_svg__b"
      >
        <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
        <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
        <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="100%"
        x2="50%"
        y2=".003%"
        id="serverless-illustration_svg__c"
      >
        <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
        <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
        <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="100.013%"
        x2="50%"
        y2="-.017%"
        id="serverless-illustration_svg__d"
      >
        <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
        <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
        <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="100.004%"
        x2="50%"
        y2="0%"
        id="serverless-illustration_svg__e"
      >
        <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
        <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
        <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M0 0h164.21v183.234H0z"
        opacity={0.8}
        transform="translate(181.79)"
        fill="url(#serverless-illustration_svg__a)"
        fillRule="nonzero"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M184.503 122.338h158.78v56.905h-158.78z"
      />
      <path
        fill="#64FFDA"
        fillRule="nonzero"
        d="M197.536 135.835h6.153v29.16h-6.153z"
      />
      <path
        d="M209.682 135.835h6.153v29.16h-6.153z"
        opacity={0.7}
        fill="#64FFDA"
        fillRule="nonzero"
      />
      <path
        d="M221.832 135.835h6.153v29.16h-6.153z"
        opacity={0.6}
        fill="#64FFDA"
        fillRule="nonzero"
      />
      <path
        d="M233.978 135.835h6.153v29.16h-6.153zM246.123 135.835h6.153v29.16h-6.153z"
        opacity={0.5}
        fill="#64FFDA"
        fillRule="nonzero"
      />
      <path
        d="M258.27 135.835h6.153v29.16h-6.153z"
        opacity={0.4}
        fill="#64FFDA"
        fillRule="nonzero"
      />
      <path
        d="M270.42 135.835h6.153v29.16h-6.153z"
        opacity={0.3}
        fill="#64FFDA"
        fillRule="nonzero"
      />
      <ellipse
        fill="#6C63FF"
        fillRule="nonzero"
        cx={320.805}
        cy={150.227}
        rx={14.847}
        ry={14.843}
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M184.503 63.216h158.78v56.905h-158.78z"
      />
      <path
        fill="#64FFDA"
        fillRule="nonzero"
        d="M197.536 76.713h6.153v29.16h-6.153z"
      />
      <path
        d="M209.682 76.713h6.153v29.16h-6.153z"
        opacity={0.7}
        fill="#64FFDA"
        fillRule="nonzero"
      />
      <path
        d="M221.832 76.713h6.153v29.16h-6.153z"
        opacity={0.6}
        fill="#64FFDA"
        fillRule="nonzero"
      />
      <path
        d="M233.978 76.713h6.153v29.16h-6.153zM246.123 76.713h6.153v29.16h-6.153z"
        opacity={0.5}
        fill="#64FFDA"
        fillRule="nonzero"
      />
      <path
        d="M258.27 76.713h6.153v29.16h-6.153z"
        opacity={0.4}
        fill="#64FFDA"
        fillRule="nonzero"
      />
      <path
        d="M270.42 76.713h6.153v29.16h-6.153z"
        opacity={0.3}
        fill="#64FFDA"
        fillRule="nonzero"
      />
      <ellipse
        fill="#6C63FF"
        fillRule="nonzero"
        cx={320.805}
        cy={91.109}
        rx={14.847}
        ry={14.843}
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M184.503 4.094h158.78v56.905h-158.78z"
      />
      <path
        fill="#64FFDA"
        fillRule="nonzero"
        d="M197.536 17.596h6.153v29.16h-6.153z"
      />
      <path
        d="M209.682 17.596h6.153v29.16h-6.153z"
        opacity={0.7}
        fill="#64FFDA"
        fillRule="nonzero"
      />
      <path
        d="M221.832 17.596h6.153v29.16h-6.153z"
        opacity={0.6}
        fill="#64FFDA"
        fillRule="nonzero"
      />
      <path
        d="M233.978 17.596h6.153v29.16h-6.153zM246.123 17.596h6.153v29.16h-6.153z"
        opacity={0.5}
        fill="#64FFDA"
        fillRule="nonzero"
      />
      <path
        d="M258.27 17.596h6.153v29.16h-6.153z"
        opacity={0.4}
        fill="#64FFDA"
        fillRule="nonzero"
      />
      <path
        d="M270.42 17.596h6.153v29.16h-6.153z"
        opacity={0.3}
        fill="#64FFDA"
        fillRule="nonzero"
      />
      <ellipse
        fill="url(#serverless-illustration_svg__b)"
        fillRule="nonzero"
        cx={320.805}
        cy={31.988}
        rx={16.005}
        ry={16.002}
      />
      <ellipse
        fill="url(#serverless-illustration_svg__b)"
        fillRule="nonzero"
        cx={320.805}
        cy={91.505}
        rx={16.005}
        ry={16.002}
      />
      <ellipse
        fill="url(#serverless-illustration_svg__b)"
        fillRule="nonzero"
        cx={320.805}
        cy={150.623}
        rx={16.005}
        ry={16.002}
      />
      <ellipse
        fill="#6C63FF"
        fillRule="nonzero"
        cx={320.805}
        cy={31.988}
        rx={14.847}
        ry={14.843}
      />
      <path
        d="M219.798 146.744H.028V6.467A6.31 6.31 0 0 1 6.341.156h207.14a6.312 6.312 0 0 1 6.313 6.311l.004 140.277z"
        opacity={0.8}
        transform="translate(13.185 131.018)"
        fill="url(#serverless-illustration_svg__c)"
        fillRule="nonzero"
      />
      <path
        d="M22.554 133.754H223.64a6.312 6.312 0 0 1 6.313 6.311v137.697H16.241V140.065a6.312 6.312 0 0 1 6.313-6.31z"
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        fill="#F5F5F5"
        fillRule="nonzero"
        d="M23.121 142.131h199.953v131.118H23.121z"
      />
      <path
        fill="#E0E0E0"
        fillRule="nonzero"
        opacity={0.8}
        d="M23.888 148.498h199.106v117.964H23.888z"
      />
      <path
        fill="#F5F5F5"
        fillRule="nonzero"
        d="M23.888 142.23h199.106v6.267H23.888z"
      />
      <ellipse
        fill="#FF5252"
        fillRule="nonzero"
        cx={28.155}
        cy={145.266}
        rx={1.518}
        ry={1.518}
      />
      <ellipse
        fill="#FF0"
        fillRule="nonzero"
        cx={32.335}
        cy={145.266}
        rx={1.518}
        ry={1.518}
      />
      <ellipse
        fill="#69F0AE"
        fillRule="nonzero"
        cx={36.518}
        cy={145.266}
        rx={1.518}
        ry={1.518}
      />
      <path
        d="M239.132 23.551c-12.234.927-222.327.556-229.67 0C-.165 18.914 0 .923 0 .923L96.349.28l.323 5.476h41.249l1.598-4.833h106.788s2.122 16.509-7.175 22.628z"
        opacity={0.8}
        transform="translate(0 263.634)"
        fill="url(#serverless-illustration_svg__d)"
        fillRule="nonzero"
      />
      <path
        d="M244.741 266.007H140.038a3.195 3.195 0 0 1-2.441 5.316H97.643a3.196 3.196 0 0 1-2.441-5.316H1.45s-.16 15.626 9.346 19.652c7.251.484 214.775.8 226.857 0 9.186-5.316 7.088-19.652 7.088-19.652z"
        fill="#F5F5F5"
        fillRule="nonzero"
      />
      <ellipse
        fill="#E0E0E0"
        fillRule="nonzero"
        cx={23.201}
        cy={269.386}
        rx={1.291}
        ry={1.29}
      />
      <ellipse
        fill="#E0E0E0"
        fillRule="nonzero"
        cx={27.069}
        cy={269.386}
        rx={1.291}
        ry={1.29}
      />
      <ellipse
        fill="#E0E0E0"
        fillRule="nonzero"
        cx={30.936}
        cy={269.386}
        rx={1.291}
        ry={1.29}
      />
      <path
        d="M38.975.148L.195 24.007s0 49.211 38.78 76.05c38.78-26.843 38.78-76.05 38.78-76.05L38.974.147z"
        opacity={0.7}
        transform="translate(77.91 152.988)"
        fill="url(#serverless-illustration_svg__e)"
        fillRule="nonzero"
      />
      <path
        d="M116.885 155.951l-36.578 22.517s0 46.44 36.598 71.768c36.598-25.328 36.598-71.768 36.598-71.768l-36.618-22.517z"
        fill="#6C63FF"
        fillRule="nonzero"
      />
      <path
        d="M117.508 155.951l-.627.4v93.47l.627.44c36.598-25.33 36.598-71.769 36.598-71.769l-36.598-22.54z"
        fill="#000"
        fillRule="nonzero"
        opacity={0.05}
      />
      <path
        d="M116.885 161.392l-32.363 19.916s0 41.08 32.363 63.488c32.362-22.409 32.362-63.488 32.362-63.488l-32.362-19.916z"
        fill="#6C63FF"
        fillRule="nonzero"
      />
      <path
        d="M116.885 161.548v83.388c32.362-22.41 32.362-63.488 32.362-63.488l-32.362-19.9z"
        fill="#000"
        fillRule="nonzero"
        opacity={0.08}
      />
      <path
        d="M95.49 122.382V39.697h68.32"
        stroke="#3AD29F"
        strokeWidth={0.8}
        strokeDasharray="4.8"
      />
      <path
        d="M74.314 97.217V14.532h68.321M286.07 190.687v64.71h-35.96"
        stroke="#3AD29F"
        strokeWidth={0.8}
        strokeDasharray="4.8"
      />
      <path
        d="M307.644 205.067v64.71h-35.958"
        stroke="#3AD29F"
        strokeWidth={0.8}
        strokeDasharray="4.8"
      />
    </g>
  </svg>
);

export default SvgServerlessIllustration;
