import React, { useMemo, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { colors } from 'midtype-backend';

export enum LanguageId {
  HTML_TAGS = 'html-tags',
  HTML_ATTRS = 'html-attributes',
  JS = 'js',
  REACT = 'react',
  NODE = 'node',
  PYTHON = 'python'
}

interface ILanguage {
  label: string;
  id: LanguageId;
}

interface ICodeSnippetHeaderProps {
  child: React.ReactElement;
  active: string;
  setActive: (id: string) => void;
}

interface ICodeSnippetProps {
  language: LanguageId;
  open?: boolean;
}

const Styled = styled.div`
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  padding: 0 1rem;

  .code-container {
    font-size: 12px;
    position: relative;
    border-top: 1px solid ${colors.GRAY_2()};
  }
  .code-container pre {
    border-radius: 0;
    margin: 0;
  }

  .code-container pre:nth-child(n + 2) {
    border-top: 2px solid ${colors.GRAY_2()};
  }

  .header {
    display: flex;
    padding: 0.75rem;
  }

  .header__language {
    font-size: 0.8rem;
    margin-right: 1rem;
    color: ${colors.GRAY_3(0.7)};
    cursor: pointer;
    transition: 250ms all;
    font-weight: 600;
  }

  .header__language--active {
    color: ${colors.PURPLE_LIGHT()};
  }

  .snippet {
    transition: all 500ms;
  }

  .snippet.enter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transform: translateX(-2rem);
  }
  .snippet.enter-active {
    position: relative;
    opacity: 1;
    transform: translateX(0);
  }
  .snippet.exit {
    position: relative;
    opacity: 1;
    transform: translateX(0);
  }
  .snippet.exit-active {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transform: translateX(2rem);
  }
`;

const LANGUAGES: { [key: string]: ILanguage } = {
  'html-tags': {
    label: 'HTML Tags',
    id: LanguageId.HTML_TAGS
  },
  'html-attributes': {
    label: 'HTML Attributes',
    id: LanguageId.HTML_ATTRS
  },
  js: {
    label: 'Javascript (ESNext)',
    id: LanguageId.JS
  },
  react: {
    label: 'React (JSX)',
    id: LanguageId.REACT
  },
  node: {
    label: 'Node',
    id: LanguageId.NODE
  },
  python: {
    label: 'Python',
    id: LanguageId.PYTHON
  }
};

const getLanguage = (child: React.ReactElement): string | null => {
  return child && child.props && child.props.mdxType === 'Snippet'
    ? child.props.language
    : null;
};

const CodeSnippetHeader: React.FC<ICodeSnippetHeaderProps> = props => {
  const { child, active, setActive } = props;
  const language = getLanguage(child);
  if (language && LANGUAGES[language]) {
    return (
      <div
        className={`header__language header__language--${
          language === active ? 'active' : 'inactive'
        }`}
        onClick={() => setActive(language)}
      >
        {LANGUAGES[language].label}
      </div>
    );
  }
  return null;
};

const CodeControlled: React.FC = props => {
  const { children } = props;
  const [active, setActive] = useState();

  if (!active) {
    const language = getLanguage(React.Children.toArray(children)[0] as any);
    if (language) {
      setActive(language);
    }
  }

  return (
    <Styled className="code-sample">
      <div className="header">
        {React.Children.map(children, (child: any) => (
          <CodeSnippetHeader
            child={child}
            active={active}
            setActive={setActive}
          />
        ))}
      </div>
      <div className="code-container">
        {React.Children.map(children, (child: any) => {
          const language = getLanguage(child);
          if (language === active) {
            return React.cloneElement(child, {
              open: true
            });
          }
          return child;
        })}
      </div>
    </Styled>
  );
};

export const CodeUncontrolled: React.FC<{
  active: LanguageId;
  setActive: (id: string) => void;
}> = props => {
  const { children, active, setActive } = props;

  if (!active) {
    const language = getLanguage(React.Children.toArray(children)[0] as any);
    if (language) {
      setActive(language);
    }
  }

  return (
    <Styled className="code-sample">
      <div className="header">
        {React.Children.map(children, (child: any) => (
          <CodeSnippetHeader
            child={child}
            active={active}
            setActive={setActive}
          />
        ))}
      </div>
      <div className="code-container">
        {React.Children.map(children, (child: any) => {
          const language = getLanguage(child);
          if (language === active) {
            return React.cloneElement(child, {
              open: true
            });
          }
          return child;
        })}
      </div>
    </Styled>
  );
};

export const Snippet: React.FC<ICodeSnippetProps> = props => (
  <CSSTransition
    in={props.open}
    timeout={500}
    unmountOnExit={true}
    mountOnEnter={true}
  >
    <div className={`snippet snippet--${props.open ? 'open' : 'closed'}`}>
      {props.children}
    </div>
  </CSSTransition>
);

export default CodeControlled;
