import React from 'react';
import styled from 'styled-components';
import { colors } from 'midtype-backend';
import { graphql, Link } from 'gatsby';

import Page from '../layout/Layout';
import Promo from '../components/Promo';

import UsersList from '../components/homepage/UsersList';
import Features from '../components/homepage/Features';
import CodeDemos from '../components/homepage/CodeDemos';

import IllustrationServerless from '../icons/IllustrationServerless';
import Arrow from '../icons/Arrow';

interface IMarketingSectionProps {
  gray?: boolean;
  align?: 'left' | 'center' | 'right';
}

const MarketingSection = styled.article`
  width: 100%;
  padding: 3rem;
  text-align: ${(props: IMarketingSectionProps) => props.align || 'center'};
  background: ${(props: IMarketingSectionProps) =>
    props.gray ? colors.GRAY_1() : colors.WHITE()};

  .container {
    position: relative;
    max-width: 60rem;
    margin: auto;
  }
  .container--grid {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
  .section-description {
    margin-bottom: 3rem;
  }
  .container__text--auth {
    grid-column: 4 / span 3;
  }
  .container__users-list {
    grid-column: 1 / span 3;
  }
  .container__text--infra,
  .container__text--services {
    grid-column: 1 / span 4;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
  }
  .container__serverless {
    grid-column: 5 / span 2;
  }
  .container h4,
  .container h2 {
    margin-bottom: 1rem;
  }
  &.section--opener {
    padding-bottom: 1rem;
  }
  &.section--opener h1 {
    font-size: 4rem;
  }
  &.section--opener .section-description {
    font-size: 1.3rem;
    line-height: 2rem;
    max-width: 48rem;
    margin: auto;
    margin-bottom: 3rem;
  }
  &.section--serverless {
    margin-bottom: 5rem;
  }
  &.section--serverless .section-description {
    max-width: 30rem;
  }
  &.section--dev {
    padding-top: 0;
    margin-top: 60px;
    margin-bottom: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 1024px) {
    padding: 20px;
  }
  @media screen and (max-width: 768px) {
    text-align: center;
    .try-midtype {
      justify-content: center !important;
    }
    &.section--opener {
      padding-top: 50px;
    }
    &.section--opener h1 {
      font-size: 3.5rem;
      line-height: 4rem;
    }
    &.section--serverless {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .section-description {
      margin-bottom: 10px;
    }
    .container__text {
      align-items: center;
    }
    .container--grid {
      grid-gap: 20px;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }
    .container__text--auth {
      grid-column: 1 / span 1;
    }
    .container__users-list {
      grid-column: 1 / span 1;
      grid-row: 2 / span 2;
    }
    .container__text--infra {
      grid-column: 1 / span 4;
    }
    .container__serverless {
      grid-column: 1 / span 4;
      grid-row: 2 / span 2;
      width: 100%;
      height: 200px;
      align-self: center;
      justify-self: center;
    }
  }
`;

const IndexPage: React.FC<any> = props => {
  const { allMarkdownRemark: md, allMdx: mdx } = props.data;
  const featured =
    parseInt(md.nodes[0].frontmatter.published, 0) <
    parseInt(mdx.nodes[0].frontmatter.published, 0)
      ? md.nodes[0]
      : mdx.nodes[0];
  return (
    <Page
      pageTitle="Launch and iterate on web apps at light speed"
      pageDescription="Midtype provides a hosted backend with user login, payments, a database, and much more built in. Focus on your core product without reinventing the wheel."
    >
      <MarketingSection className="section--opener">
        <div className="container">
          <Link to={featured.fields.slug}>
            <Promo text={featured.frontmatter.title_short} />
          </Link>
          <h1 style={{ margin: 'auto', marginBottom: 20 }}>
            Launch and iterate on&nbsp;
            <br className="drop-at-med" />
            web apps at light speed.
          </h1>
          <p className="large section-description">
            Midtype provides a hosted backend with user login, payments, a
            database, and much more built in. Focus on your core product without
            reinventing the wheel.{' '}
            <Link to="/blog/firebase-alternative/">
              See how Midtype compares to Firebase&nbsp;
              <Arrow
                fill={colors.PURPLE_LIGHT()}
                style={{ marginLeft: 5, marginBottom: -2 }}
              />
            </Link>
          </p>
        </div>
      </MarketingSection>

      <CodeDemos />

      <MarketingSection align="left" className="section--auth" gray={true}>
        <div className="container container--grid">
          <UsersList className="container__users-list" />
          <div className="container__text container__text--auth">
            <h4 className="text-gray">User Management</h4>
            <h2>It starts with users.</h2>
            <p className="section-description">
              Midtype provides a flexible GraphQL API with user authentication
              built in and templates for login pages. Let users register with
              common identity providers, like Google.
            </p>
          </div>
        </div>
      </MarketingSection>

      <MarketingSection>
        <Features />
      </MarketingSection>

      <MarketingSection
        align="right"
        className="section--serverless"
        gray={true}
      >
        <div className="container container--grid">
          <div className="container__text container__text--infra">
            <h4 className="text-gray">You Own Your Data</h4>
            <h2>
              Never locked in to
              <br />
              our infrastructure.
            </h2>
            <p className="section-description">
              Midtype automatically provisions the ideal backend infrastructure
              for your app. But rest assured, you can always migrate data and
              compute services to any cloud or on-premise.{' '}
              <Link to="/pricing">Learn more</Link>.
            </p>
          </div>
          <IllustrationServerless
            height="250px"
            width="100%"
            className="container__serverless"
          />
        </div>
      </MarketingSection>
    </Page>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fields: { template: { eq: "blog" } }
        frontmatter: { featured: { eq: true } }
      }
      sort: { fields: frontmatter___published, order: DESC }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title_short
          published(difference: "0")
        }
      }
    }
    allMdx(
      filter: { fields: { template: { eq: "customers" } } }
      sort: { fields: frontmatter___published, order: DESC }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title_short
          published(difference: "0")
        }
      }
    }
  }
`;

export default IndexPage;
