import * as React from "react";

const SvgUsersIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 114 290" {...props}>
    <defs>
      <linearGradient
        x1="50.001%"
        y1="100.001%"
        x2="50.001%"
        y2="-.016%"
        id="users-illustration_svg__a"
      >
        <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
        <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
        <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
      </linearGradient>
    </defs>
    <g fillRule="nonzero" fill="none">
      <ellipse
        fill="#6C63FF"
        opacity={0.1}
        cx={57.03}
        cy={279.25}
        rx={57}
        ry={10.26}
      />
      <path
        d="M25.03 148.52c2.88 1.7 7.4 2.81 13.09-.85l-.05 8.11c.4 5.56 7.28 25.83 7.28 25.83l4.85 16.67a130.07 130.07 0 0 1 5.33 36.28c0 4.06.38 8.26 1.55 11.42 2.59 7-1 16-2.57 19.32a80.1 80.1 0 0 1-8 2.25 5.83 5.83 0 0 0-.71.22v-.05s-7.28 2.89-10.52 2.49c-2.41-.29-3.92 2.72-2 4.93a14.88 14.88 0 0 1-4.84.64c-3.24-.4-4.86 5.16 1.21 6.75 6.07 1.59 40.46 0 40.46 0a4.87 4.87 0 0 0 2.58-5.38l4.3-.18s6.07-2.78 0-10.33l-.33.24-1.29-3.82v-5.15s7.69-37 5.26-41.33c-2.43-4.33-3.72-26.67-3.72-26.67l.85-24.2c2.19-4.52 3.31-11.62 3.88-17.17a36 36 0 0 0 1.55-8.14c.1-1.31.18-2.67.23-4.09a27.27 27.27 0 0 0-1.48-9.16 92.06 92.06 0 0 1 9.17-11.5 11.82 11.82 0 0 0 3.65-10.33l-2-13.51-4.86-23c.61-6.39-.72-10.07-2.2-12.15.35-2.07.68-4.13.93-6.21a79.6 79.6 0 0 0-1.42-27c-.7-3.08-1.66-6.25-3.83-8.59-2.45-2.66-6.43-4.27-7.35-7.74a20.66 20.66 0 0 0-.56-2.62c-.89-2.27-3.69-3.1-6.15-3.4-4.38-.53-10.71-.93-14.92.79a18.09 18.09 0 0 0-8.64 8.02 6.89 6.89 0 0 0-1 4.95 6.6 6.6 0 0 0 .69 1.7c-2.916 7.907 1.069 16.689 8.94 19.7.1.31.19.61.26.9.185.713.319 1.438.4 2.17-.08 1.4-.17 2.81-.23 4.22l-.15.57A105.13 105.13 0 0 0 42.5 49.3c2.42 3.58-.41 18.68-.41 18.68-6.07 3.57-12.13 12.71-5.66 22.25 6.47 9.54 2.83 19.86 2.83 19.86l-1 15.54-.2 3.11h.07l-.07 1.18h.17v1.27c-2.84 1.05-7 3.15-9.47 7.05a24.94 24.94 0 0 1-4.23 5.19 3.12 3.12 0 0 0 .5 5.09zm48.85-41.28a14.77 14.77 0 0 1 1.44-7.82h2.86c.084.632.201 1.26.35 1.88.82 3.55-2.46 5.22-4.65 5.94z"
        fill="url(#users-illustration_svg__a)"
      />
      <path
        d="M74.68 261.24l1.56 4.67-5.06 5.06-16.74 3.94-12.07-1.17.78-4.28a5.17 5.17 0 0 1 3.83-3.78c5-1.16 14.42-3.82 16.41-7.51l11.29 3.07z"
        fill="#DB8B8B"
      />
      <path
        d="M74.68 261.24l1.56 4.67-5.06 5.06-16.74 3.94-12.07-1.17.78-4.28a5.17 5.17 0 0 1 3.83-3.78c5-1.16 14.42-3.82 16.41-7.51l11.29 3.07z"
        fill="#000"
        opacity={0.1}
      />
      <path
        d="M46.26 272.53l2.34-3.11-2.34-3.61s-7 2.83-10.12 2.44c-3.12-.39-4.67 5.06 1.17 6.62 5.84 1.56 38.93 0 38.93 0s5.84-2.73 0-10.13l-5.06 3.9s-8.57 4.67-12.85 3.89H46.26z"
        fill="#C17174"
      />
      <path
        d="M35.76 279.15l12.07 1.17 16.74-3.9 5.06-5.06-.41-1.23-.58-1.74-.57-1.7-11.31-3.11a7 7 0 0 1-2.29 2.29c-3.67 2.51-10.25 4.32-14.12 5.22a3.82 3.82 0 0 0-.48.15 5.1 5.1 0 0 0-3.38 3.63l-.73 4.28z"
        fill="#DB8B8B"
      />
      <path
        d="M35.76 279.15l12.07 1.17 16.74-3.9 5.06-5.06-.41-1.23-4.65 3.57s-8.57 4.67-12.85 3.89H39.64l2.34-3.11-2.1-3.24a5.1 5.1 0 0 0-3.38 3.63l-.74 4.28z"
        fill="#000"
        opacity={0.1}
      />
      <path
        d="M39.64 277.98l2.34-3.11-2.34-3.61s-7 2.83-10.12 2.44c-3.12-.39-4.67 5.06 1.17 6.62 5.84 1.56 38.93 0 38.93 0s5.84-2.73 0-10.12l-5.06 3.89s-8.57 4.67-12.85 3.89H39.64z"
        fill="#C17174"
      />
      <path
        d="M49.37 52.18s18.69 2 16-5.84a13.91 13.91 0 0 1-.58-6.88 18.94 18.94 0 0 1 3.3-7.91l-17.52 2.72a21.86 21.86 0 0 1 2.38 5.63c2.05 8.26-3.58 12.28-3.58 12.28z"
        fill="#DB8B8B"
      />
      <path
        d="M81.3 140.91s-.39 16.74-4.28 24.92l-.78 23.75s1.16 21.8 3.5 26.08c2.34 4.28-5.06 40.49-5.06 40.49v6.23s-12.46 4.67-16-.78l3.5-46.33-1.56-64.23 20.68-10.13z"
        fill="#474463"
      />
      <path
        d="M81.3 140.91s-.39 16.74-4.28 24.92l-.78 23.75s1.16 21.8 3.5 26.08c2.34 4.28-5.06 40.49-5.06 40.49v6.23s-12.46 4.67-16-.78l3.5-46.33-1.56-64.23 20.68-10.13z"
        fill="#000"
        opacity={0.1}
      />
      <path
        d="M89.08 87.22l2.34 6.23 2 13.23a11.75 11.75 0 0 1-3.51 10.13c-4.67 4.67-10.9 14.4-10.9 14.4l-7.39-22.19s7.39-1.17 6.22-6.23c-1.17-5.06-.77-14.4-.77-14.4l12.01-1.17z"
        fill="#FF748E"
      />
      <path
        d="M89.17 87.13l2.33 6.23 2 13.24a11.7 11.7 0 0 1-3.5 10.12c-4.68 4.67-10.9 14.41-10.9 14.41l-7.45-22.22s7.4-1.17 6.23-6.23c-1.17-5.06-.78-14.41-.78-14.41l12.07-1.14z"
        fill="#000"
        opacity={0.05}
      />
      <path
        d="M54.48 265.91c5 7.86 12.77 3.4 14.15 2.52l-.57-1.7-11.3-3.15a7 7 0 0 1-2.28 2.33z"
        fill="#000"
        opacity={0.1}
      />
      <path
        d="M38.86 156.13c.39 5.45 7 25.3 7 25.3l4.67 16.34a130.39 130.39 0 0 1 5.13 35.54 34.71 34.71 0 0 0 1.49 11.19c3.12 8.57-3.11 20.24-3.11 20.24 5.45 9.74 14.79 3.12 14.79 3.12v-6.23s7.4-33.87 5.45-40.49c-1.95-6.62-2.72-24.52-2.72-24.52V164.3a36.67 36.67 0 0 0 10.68-23.24c.09-1.28.17-2.62.22-4 .31-8.78-5.7-19.08-8-22.75-.56-.89-.92-1.39-.92-1.39l-34.37 13.64h-.08v4.29l-.23 25.28z"
        fill="#474463"
      />
      <path
        d="M50.54 34.27a21.86 21.86 0 0 1 2.38 5.63 15.22 15.22 0 0 0 11.84-.44 18.94 18.94 0 0 1 3.3-7.91l-17.52 2.72z"
        fill="#000"
        opacity={0.1}
      />
      <circle fill="#DB8B8B" cx={58.33} cy={24.93} r={15.18} />
      <path
        d="M38.86 130.82h.17c16.32 3.36 36.82-13.65 36.82-13.65a20.31 20.31 0 0 1-1.42-2.9c-.56-.89-.92-1.39-.92-1.39l-34.37 13.64-.09 1.36-.19 2.94z"
        fill="#000"
        opacity={0.1}
      />
      <path
        d="M43.15 51.79s21.8-13.24 28.8-7.79l9.74 12.46s6.23 1.95 5.07 14.45l4.67 22.58-14 3.5s-8.17 7.79-1.55 19.08c0 0-20.64 17.13-37 13.62l1.17-18.29s3.5-10.13-2.72-19.47c-6.22-9.34-.39-18.3 5.45-21.8-.02-.04 2.7-14.84.37-18.34z"
        fill="#FF748E"
      />
      <path
        d="M41.98 131.21s-8.18 1.56-12.07 7.79a24.3 24.3 0 0 1-4.08 5.09 3.08 3.08 0 0 0 .49 4.92c3.09 1.86 8.12 3 14.49-2.23 10.95-8.95 1.17-15.57 1.17-15.57z"
        fill="#DB8B8B"
      />
      <path
        d="M54.05 59.19s7.78-.78 9.73 19.46c1.95 20.24 5.06 24.92 5.06 24.92s1.95 10.51-2.73 16.74c-4.68 6.23-13.62 19.47-13.62 19.47s-9.73 2.72-11.68-7l12.07-22.58s1.94-7.79-2.73-13.24-8.17-41.67 3.9-37.77z"
        fill="#000"
        opacity={0.1}
      />
      <path
        d="M53.27 58.02s7.78-.78 9.73 19.47c1.95 20.25 5.06 24.91 5.06 24.91s2 10.51-2.72 16.74c-4.72 6.23-13.58 19.47-13.58 19.47s-9.73 2.72-11.68-7l12.07-22.58s1.95-7.79-2.73-13.24-8.22-41.66 3.85-37.77z"
        fill="#FF748E"
      />
      <path
        d="M46.84 72.62s-.77 12.07 2.73 18.69c3.5 6.62 3.5 15.18 0 19.85M44.9 57.82s3.11-3.11 12.07-3.5c8.96-.39 12.84-3.11 12.84-3.11"
        fill="#000"
        opacity={0.1}
      />
      <path
        d="M66.55 5.31c2.37.29 5.07 1.1 5.92 3.32.234.845.418 1.703.55 2.57.88 3.4 4.71 5 7.07 7.58 2.08 2.3 3 5.4 3.68 8.43a79.17 79.17 0 0 1 1.37 26.45c-.76 6.45-2.32 12.81-2.75 19.29-.43 6.48.36 13.31 3.9 18.76 1.53 2.34 3.54 4.4 4.62 7-2.23 1.81-5.31 2.07-8.19 2.17-3.333.113-6.683.113-10.05 0a10.67 10.67 0 0 1-5.1-1.07 7.86 7.86 0 0 1-2.68-2.87c-1.92-3.25-2.4-7.16-2.32-10.93.08-3.77.68-7.52.75-11.3a9.82 9.82 0 0 0-1.31-5.92 19.07 19.07 0 0 0-3.26-3c-4.17-3.7-5.6-9.62-5.74-15.19-.14-5.57.78-11.14.31-16.69-.18-2.17-1-4.78-3.13-5.2a11.38 11.38 0 0 1-2-.26c-1.71-.66-1.54-3.07-2-4.84-.55-2-2.25-3.53-2.73-5.53-1.12-4.71 4.88-10.34 8.81-12 3.93-1.66 10.07-1.29 14.28-.77z"
        fill="#000"
        opacity={0.1}
      />
      <path
        d="M66.94 4.53c2.37.29 5.07 1.1 5.92 3.33.235.841.419 1.696.55 2.56.88 3.4 4.71 5 7.07 7.58 2.08 2.3 3 5.4 3.68 8.43a79.17 79.17 0 0 1 1.36 26.48c-.75 6.45-2.31 12.81-2.74 19.3-.43 6.49.36 13.3 3.9 18.75 1.53 2.35 3.54 4.4 4.62 7-2.23 1.82-5.31 2.07-8.19 2.17-3.333.107-6.683.107-10.05 0a10.66 10.66 0 0 1-5.1-1.06 7.89 7.89 0 0 1-2.68-2.88c-1.92-3.25-2.4-7.16-2.32-10.93.08-3.77.68-7.52.75-11.29 0-2.06-.12-4.24-1.31-5.92a18.73 18.73 0 0 0-3.26-3c-4.17-3.7-5.6-9.62-5.74-15.19-.14-5.57.78-11.14.31-16.69-.18-2.17-1-4.78-3.13-5.2a11.38 11.38 0 0 1-2-.26c-1.71-.66-1.54-3.07-2-4.84-.55-2-2.25-3.52-2.73-5.53-1.12-4.71 4.88-10.34 8.81-12 3.93-1.66 10.1-1.33 14.28-.81z"
        fill="#464353"
      />
    </g>
  </svg>
);

export default SvgUsersIllustration;
