import React from 'react';
import styled from 'styled-components';
import { colors, styles } from 'midtype-backend';

import Rocket from '../../icons/Rocket';
import Magic from '../../icons/Magic';
import Money from '../../icons/MoneyCheckAlt';
import ThumbsUp from '../../icons/ThumbsUp';
import File from '../../icons/FileImage';
import Layers from '../../icons/LayerGroup';
import Users from '../../icons/UsersClass';
import Database from '../../icons/Database';
import Shield from '../../icons/ShieldCheck';
import Stripe from '../../icons/Stripe';
import Postgres from '../../icons/PostgreSQL';
import GraphQL from '../../icons/GraphQL';

const Styled = styled.div`
  .top-header h5 {
    font-weight: 700;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 4px;
    margin-bottom: 1rem;
  }
  .top-header {
    max-width: 600px;
    margin: auto;
  }
  .features {
    margin: 50px auto;
    margin-top: 80px;
    max-width: 65rem;
    display: grid;
    grid-gap: 5rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  @media screen and (max-width: 768px) {
    .features {
      margin: 30px auto;
      grid-gap: 2rem;
    }
  }
  @media screen and (max-width: 500px) {
    .features {
      grid-template-columns: 1fr;
      grid-auto-rows: max-content;
    }
  }
`;

const StyledFeature = styled.div`
  padding: 20px;
  box-shadow: ${styles.BOX_SHADOW};
  border-radius: 5px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  transition: 250ms transform;
  will-change: transform;

  &:hover {
    transform: scale(1.05);
  }

  .coming-soon {
    position: absolute;
    top: 10%;
    left: -33%;
    width: 100%;
    padding: 6px 0;
    text-align: center;
    color: ${(props: IFeatureProps) => colors[props.color](1)};
    font-weight: 600;
    background: ${(props: IFeatureProps) => colors[props.color](0.4)};
    box-shadow: ${styles.BOX_SHADOW};
    transform: rotate(-45deg);
  }

  .icon {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    background: ${(props: IFeatureProps) => colors[props.color](0.2)};
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 25px;
  }
  .icon svg {
    fill: ${(props: IFeatureProps) => colors[props.color](1)};
    width: 40px;
    height: 40px;
  }
  h5 {
    margin-bottom: 5px;
  }
  .stretcher {
    flex: 1 1 auto;
  }
  .integrates-with {
    margin: -20px;
    margin-top: 20px;
    padding: 10px 20px;
    background: ${(props: IFeatureProps) => colors[props.color](0.4)};
  }
  .integrates-with svg {
    width: 100%;
    height: 35px;
    fill: ${colors.WHITE()};
  }
  @media screen and (max-width: 1024px) {
    .coming-soon {
      top: 7%;
    }
  }
  @media screen and (max-width: 500px) {
    h5 {
      font-size: 1.5rem;
    }
    p {
      font-size: 1.2rem;
      line-height: 1.7rem;
    }
    .coming-soon {
      top: 12%;
    }
  }
`;

interface IFeatureProps {
  title: string;
  icon: React.FC;
  description: string;
  color:
    | 'GREEN'
    | 'RED'
    | 'ORANGE'
    | 'PURPLE_LIGHT'
    | 'PURPLE_DARK'
    | 'BLUE_LIGHT'
    | 'BLUE_DARK';
  soon?: string;
  integration?: React.FC;
}

const Feature: React.FC<IFeatureProps> = props => (
  <StyledFeature {...props}>
    {props.soon && <div className="coming-soon">{props.soon}</div>}
    <div className="icon">
      <props.icon />
    </div>
    <h5>{props.title}</h5>
    <p>{props.description}</p>
    <div className="stretcher" />
    {props.integration && (
      <div className="integrates-with">
        <props.integration />
      </div>
    )}
  </StyledFeature>
);

const Features: React.FC = () => {
  return (
    <Styled>
      <div className="top-header">
        <h5>Features</h5>
        <p>
          Hit the ground running on your app with Midtype's toolkit of services.
          Leverage one-click integrations to support payments, marketing
          campaigns, and more.
        </p>
      </div>
      <div className="features">
        <Feature
          icon={Shield}
          title="Access Control"
          description="Easily authenticate requests to your API with fine controls over what your users can see and edit."
          color="GREEN"
        />
        {/*<Feature
          icon={Layers}
          title="GraphQL"
          description="Immediately get your own GraphQL API that exposes all the features and functionality Midtype has to offer."
          color="ORANGE"
          integration={GraphQL}
        />*/}
        <Feature
          icon={Database}
          title="Hosted Database"
          description="Get an optimized database to back your GraphQL API. No need to manage any hosting or infrastructure."
          color="BLUE_DARK"
          integration={Postgres}
        />
        {/*<Feature
          icon={File}
          title="Asset Storage"
          description="Enable asset storage on your API to make it simple to upload and store photos and videos."
          color="RED"
        />*/}
        <Feature
          icon={Money}
          title="Payments"
          description="Connect your Stripe account to enable users to subscribe to plans for your service."
          color="PURPLE_DARK"
          integration={Stripe}
        />
        <Feature
          soon="Coming Soon"
          icon={Magic}
          title="Flexible Webhooks"
          description="Reach out to any service whenever a new user registers or new data is populated through your API."
          color="PURPLE_LIGHT"
        />
        <Feature
          soon="Coming Soon"
          icon={Rocket}
          title="Marketing"
          description="Sync your users and their plans with CRM tools to streamline marketing and outreach."
          color="BLUE_LIGHT"
        />
        {/*<Feature
          soon="Coming Soon"
          icon={ThumbsUp}
          title="Social Feedback"
          description="Easily extend your API to count likes on posts or implement follow requests like Instagram."
          color="ORANGE"
        />*/}
        <Feature
          soon="Coming Soon"
          icon={Users}
          title="Customer Segmentation"
          description="Offer tiered services by segmenting customers by paid plans or feature flags."
          color="GREEN"
        />
      </div>
    </Styled>
  );
};

export default Features;
