import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { colors, styles } from 'midtype-backend';

import Logo from './Logo';

const Styled = styled.header`
  position: fixed;
  width: 100vw;
  height: 3.75rem;
  padding: 0 30px;
  top: 0;
  left: 0;
  background: ${colors.WHITE(0.9)};
  display: grid;
  grid-gap: 0px;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: 1fr;
  z-index: ${styles.ZINDEX_MASK + 5};

  .header__text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  p {
    font-weight: 500;
  }

  @media screen and (max-width: 1024px) {
    padding: 0 20px;
    grid-template-columns: 1fr 4fr 1fr;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    height: auto;
    .header__logo {
      display: none;
    }
    .header__text {
      margin: 1.5rem 0;
    }
  }
`;

const Header: React.FC<any> = () => {
  return (
    <Styled>
      <div className="header__logo flex">
        <Link to="/">
          <Logo width={110} />
        </Link>
      </div>
      <div className="header__text">
        <p className="text-no-margin">
          Midtype has shut down. The team is now working on{' '}
          <a href="https://www.heraldhq.com">
            Herald, helping product teams make customer-oriented decisions
          </a>
          .
        </p>
      </div>
    </Styled>
  );
};

export default Header;
